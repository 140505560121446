import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import AccordionMap from '../accordion-map'
import { useOffices } from '../../../../hooks/use-offices'
import { sortByCities } from '../../../../helpers/sort-by-country'

const WorkWithUsMobile = ({ filterSelected, t }) => {
  const [officesSorted, setOfficesSorted] = useState([])
  const [officesFilterByContinent, setOfficesFilterByContinent] = useState([])
  const { offices } = useOffices()

  useEffect(() => {
    if (!_.isEmpty(filterSelected)) {
      const officesFiltered = _.filter(officesSorted, (office) => office.continent === filterSelected)
      setOfficesFilterByContinent(officesFiltered)
    } else {
      setOfficesFilterByContinent(officesSorted)
    }
  }, [filterSelected, officesSorted])

  useEffect(() => {
    const result = sortByCities(offices)
    setOfficesSorted(result)
  }, [offices])

  return (

    <AccordionMap data={ officesFilterByContinent} t={t} />

  )
}

export default WorkWithUsMobile
