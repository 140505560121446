import React from 'react'
import {
  Wrapper,
  Map,
} from '../../../../components'

const WorkWithUsDesktop = ({
  filterSelected,
  t,
}) => (
  <Wrapper
    paddingD="0 15px"
    widthD="1240px">
    <Map address filter={filterSelected} t={t} isContact/>
  </Wrapper>
)

export default WorkWithUsDesktop
