import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useWindowDimensions } from '../../helpers'
import {
  Wrapper,
  Subtitle,
  H1,
  P,
  Seo,
  Spinner,
  H2,
} from '../../components'

import { Filters } from './components'
import WorkWithUsDesktop from './components/desktop-view'
import WorkWithUsMobile from './components/mobile-view'

const WorkWithUsView = ({
  t,
  loading,
  data,
  filters,
  location,
}) => {
  const [filterSelected, setFilterSelected] = useState('asia')
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const handleFilter = (filter) => {
    setFilterSelected(filter)
  }

  useEffect(() => {
    const continent = _.get(location, 'state.continent')
    setFilterSelected(continent)
  }, [location])

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <React.Fragment>
      <Seo
        title={t('map.title')}
        description={_.get(data, 'acf.header_section.section_highlight')}
        schema={_.get(data, 'yoast_head_json.schema')}
        type={_.get(data, '[0].yoast_head_json.og_type')}
      />
      <Wrapper flexDirectionD="column">
        <Wrapper
          flexDirectionD="column"
          displayM="box"
          paddingD="80px 0">
          <Wrapper
            widthD="1920px"
            flexDirectionD="column"
            marginM="0 0 120px 0"
          >
            <Wrapper
              widthD="750px"
              marginM="32px 0"
              paddingM="25px"
              flexDirectionD="column"
              style={{ overflow: 'hidden' }}
            >
              <Subtitle>{t('map.title')}</Subtitle>
              <Wrapper
                style={{ textAlign: 'center' }}

                widthD="475px"
                marginD="12px 0 32px 0">
                <H2
                  fontSize="2xll"
                  lineHeight="4xll"
                  fontSizeD="4xl"
                  lineHeightD="6xl"

                >{t('map.subtitle')}</H2>
              </Wrapper>
              <P
                style={{ textAlign: 'center' }}
                lineHeight={'3xl'}
                lineHeightD={'3xl'}
              >
                {_.get(data, 'acf.header_section.section_highlight')}
              </P>

            </Wrapper>
            <Wrapper>
              <Filters
                filterSelected={filterSelected}
                onClick={handleFilter}
                filters={filters}
              />
            </Wrapper>
            {width >= 768 && <WorkWithUsDesktop
              filterSelected={filterSelected}
              t={t}
            />}
            {data && width < 768 && <WorkWithUsMobile
              filterSelected={filterSelected}
              t={t}
            />}

          </Wrapper>
        </Wrapper>
      </Wrapper>
    </React.Fragment>
  )
}

export default WorkWithUsView
