/* eslint-disable no-confusing-arrow */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import DropdownArrow from '../../../../assets/icons/TAM-icon-dropdown.svg'
import MinusIcon from '../../../../assets/icons/TAM-icon-minus.svg'
import PlusIcon from '../../../../assets/icons/TAM-icon-plus.svg'

import { Wrapper, CardAddress } from '../../../../components'
import { device } from '../../../../theme/device'
import './accordion-map-style.scss'

const WrapperAccordionStyle = styled.div`
  min-height: 50px;
  width: 100%;
  position: relative;
  display: block;
  border-top: 1px solid #E6E6E6;
  padding: 32px 0;
`
const WrapperArrow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`
const WrapperContent = styled.div`
  height: 0;
  width: 100%;
  padding: 0;
  background-color: transparent;
  color: black;
  font-size: 14px;
  text-align: center;
  position: relative;
  text-align: left;
  transition: all 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  > div {
    display: none;
  }
  ${(props) => props.isOpen
    && css`
      margin-top: 0px;
      height: auto;
      transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
      > div {
        display: block;
      }
    `};

`

const TitleAccordionStyle = styled.p`
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-family: ${({ theme }) => theme.font.manrope};
  color: ${(props) => (props.color
    ? props.theme.colors[props.color]
    : props.theme.colors.secondary)};
  z-index: 2000;
  font-weight: 600;
`
const TextOffices = styled.p`
  color: #6D6D6D;
  font-family: Manrope;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;

`

const Tag = styled.div`
  border-radius: 2px;
  background-color: #162934;
  width: fit-content;
  padding: 1px 5px 2px;
  margin-left: 14px;
  span {
    color: #FFFFFF;
    font-family: Manrope;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
  }
`

const sortOffices = (off) => off.sort((a) => (_.get(a, 'acf.office_section.type') === 'headquarter' ? -1 : 1))

const Head = ({
  i,
  accordionItems,
  click,
  numberOffices,
  isHeadquarter,
  t,
}) => (
  <Wrapper
    flexDirectionM="row"
    justifyContentM="space-between"
    displayM="block"
    heightM="60px"
    backgroundColor="#fff"
    zIndex="2000"
  >
    <Wrapper
      justifyContentM="flex-start"
      alignItemsM="flex-start"
    >
      <TitleAccordionStyle>{i.title}</TitleAccordionStyle>
      <Wrapper
        justifyContentM="flex-start"
        alignItemsM="center"
        flexDirectionM="row"
        marginM="8px 0"
      >
        <TextOffices>{numberOffices} {t('map.offices', { count: numberOffices })}</TextOffices>
        {isHeadquarter && <Tag><span>{t('map.headquarter')}</span></Tag>}
      </Wrapper>
    </Wrapper>
    <WrapperArrow
      onClick={() => click(accordionItems.indexOf(i))}
      isOpen={i.open}
    >
      {i.open ? <MinusIcon width={30} /> : <PlusIcon width={30} />}
    </WrapperArrow>

  </Wrapper>
)

const sections = (accordionItems, click, t) => _.map(accordionItems, (item, idx) => {
  if (accordionItems.length === 0) return null
  return (
    <WrapperAccordionStyle key={item.title + idx}>

      <Head
        t={t}
        i={item}
        click={click}
        isHeadquarter={item.isHeadquarter}
        accordionItems={accordionItems}
        numberOffices={item.children.length}
      />
      <WrapperContent WrapperContent isOpen={item.isOpenByDefault ? true : item.open}>
        {
          _.map(sortOffices(item.children), (child, i) => (
            <CardAddress key={i} info={child} />

          ))
        }
      </WrapperContent>
    </WrapperAccordionStyle>
  )
})

const AccordionMap = ({ data, t }) => {
  const [accordionItems, setAccordionItems] = useState([])
  useEffect(() => {
    const accordion = []
    if (data) {
      data.forEach((i) => {
        accordion.push({
          title: i.country,
          sequence: i.sequence,
          number: i.number,
          children: i.offices,
          isHeadquarter: i.isHeadquarter,
          // isOpenByDefault: i.country === 'Hong Kong',
          open: false,
        })
      })
      setAccordionItems(accordion, 'sequence')
    }
  }, [data])

  function click(i) {
    const newAccordion = accordionItems.slice()
    newAccordion[i].open = !newAccordion[i].open
    setAccordionItems(newAccordion)
  }

  return <div className="accordion">{sections(_.sortBy(accordionItems, 'sequence'), click, t)}</div>
}

export default AccordionMap
