import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper, Button } from '../../../../components'

const Filters = ({
  onClick,
  filters,
  filterSelected,
}) => {
  const getColor = (slug) => (slug !== 'partners' ? '#E20613' : '#162934')
  return (

    <Wrapper
      flexDirectionM="column"
      paddingM="0 15px"
      marginM="0 0 15px 0"
      marginD="64px 0"
      widthD="660px"
    >

      {
        _.map(filters, (filter) => (
          <Wrapper
            onClick={() => onClick(filter.slug)}
            key={filter.slug}
            paddingM="10px"
            widthD="200px">
            <Button
              style={{
                backgroundColor: filter.slug === filterSelected ? getColor(filter.slug) : 'white',
                color: filter.slug === filterSelected ? 'white' : getColor(filter.slug),
                borderColor: getColor(filter.slug),
              }}
            >
              {filter.name}
            </Button>
          </Wrapper>

        ))
      }

    </Wrapper>
  )
}

export default Filters
