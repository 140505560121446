import _ from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import WorkWithUsView from './connect-with-us-view'
import { makeRequest, changelanguages } from '../../helpers'
import { useOffices } from '../../hooks/use-offices'

const WorkWithUSController = (props) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const { offices, continents } = useOffices()
  const { location } = props

  const getHomeData = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        lang: changelanguages(i18n.language),
        slug: 'connect-with-us',
      },
    }).then((resp) => {
      setData(resp.data[0])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getHomeData()
  }, [i18n.language])
  console.log('continents', continents)
  const viewProps = {
    loading,
    data,

    filters: continents,
    location,
    t,
  }
  return (
    <WorkWithUsView {...viewProps} />
  )
}

export default WorkWithUSController
